body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1 {
  font-family: 'Courier Prime';
  font-size: 48px;
  line-height: 53.91px;
  font-weight: 700;
  
  margin: -5px;
  letter-spacing: 7px;
  
}

h2 {
  font-family: 'Courier Prime';
  font-size: 36px;
  line-height: 40.43px;
  font-weight: 700;
  letter-spacing: 3px;
}

h3 {
  font-family: 'Courier Prime';
  font-size: 24px;
  line-height: 26.95px;
  font-weight: 700;
}

h4 {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 700;
  margin-bottom: -10px;
  margin-top: 0px;
}

a {
  font-family: 'Courier Prime';
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
}

p {
  font-family: 'Inter';
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

html {
  scroll-behavior: smooth;
}

@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@700&family=DM+Sans:opsz,wght@9..40,700&family=Inter&display=swap');