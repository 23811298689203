.jogg {
    top: 80px;
    position: absolute;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    padding-bottom: 20px;
    
}
p {
    max-width: 68vw;
}

.clients-mobile {
    display: none;
}


.mobile-years {
    display: none;
}

.year3-box-mobile {
    display: none;
}

.jogg p {
    text-align: left;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.jogg h2 {
    text-align: left;
    margin: 0px;
    margin-top: 50px;
}

.dropdown-menu-jogg {
    display: none;
}

.highlight {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding-left: 10px;
    width: 406px;
    height: 32px;
    background-color: #E8F6F8;
    margin-top: 11px;
    margin-bottom: 11px;
}

.highlight h6 {
    margin: 0;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    font-family: Inter;
    letter-spacing: 0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.jogg-header {
    height: 140px;
    width: 100vw;
    background-color: #E8F6F6;
    border-bottom: 2px solid #B6CfD3;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.jogg-header svg {
    margin-top: 20px;
}

.jogg-headline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tabular-layout-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 81vw;
    
    height: auto;
    gap: 62px;
    
}

.tabular-layout-main-scrolled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 81vw;
    gap: 62px;
    margin-left: 113px;
}

.jogg-section-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 1850px;
}

.section-1-cluster {
    display: flex;
    flex-direction: row;
}

.section-1-text {
    display: flex;
    flex-direction: column;
    width: 506px;
    align-items: flex-start;
}
.section-1-image {
    position: relative;
    right: 0vw;
    top: 50px;
}

.section-1-image img {
    max-width: 35vw;
}

.jogg-carousel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}
.jogg-carousel img {
    position: relative;
    top: 0px;
    width:  600px;
    overflow: hidden;
}

.jogg-section-1 .next-button {

    position: absolute;
    bottom: 300px;
}

.next-button button {
    border: 0;
    background-color: white;
    font-family: "Courier Prime";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.08em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-top: 10px;
    box-shadow: inset 0 -2px 0 -1px black;
    transition: box-shadow .3s ease-in-out;
    
}

.next-button button:hover {
    color: white;
    box-shadow: inset 0 -40px 0 -1px black;
}
.timeline-container-main {
    margin-top: 40px;
    text-align: left;
    
}
.timeline-container-main h1 {
    font-family: "Courier Prime";
    font-size: 36px;
    font-weight: 700;
    line-height: 40.43px;
    letter-spacing: 0.12em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.timeline-container {
    display: flex;
    flex-direction: row;
    gap: 43px;
    
}
.timeline-container h2 {
    font-family: "Courier Prime";
    font-size: 24px;
    font-weight: 700;
    line-height: 26.95px;
    letter-spacing: 0.12em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}
.timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
}

.timeline h6 {
    font-family: "Courier Prime";
    font-size: 20px;
    font-weight: 700;
    line-height: 22.46px;
    letter-spacing: 0.12em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 20px;

}

.year1 {
    display: flex;
    height: 1672px;
    width: 3px;
    background-color: #E8F6F6;

}


.year2 {
    height: 1532px;
    width: 3px;
    background-color: #E7F2E5;
}

.year3 {
    height: 822px;
    width: 3px;
    background-color: #E8F6F6;
}

.year4 {
    height: 1194px;
    width: 3px;
    background-color: #E7F2E5;
}

.year5 {
    height: 3143px;
    width: 3px;
    background-color: #E8F6F8;
}

.year6 {
    height: 1278px;
    width: 3px;
    background-color: #E8F6F8;
}

.year7 {
    height: 1856px;
    width: 3px;
    background-color: #E7F2E5;
}
.jogg-content {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    max-width: 70vw;
}

.year1-content {
    margin-top: 29px;
    display: flex;
    flex-direction: column;
    height: 1715px;
}

.year1-content p {
    width: 825px;
}
.year2-content {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    height: 1528px;
}
.year2-content p {
    width: 825px;
}

.year3-content {
    margin-top: 29px;
    display: flex;
    flex-direction: column;
    height: 865px;
}

.year3-content p {
    width: 825px;
}

.year3-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 55vw;
    height: auto;
    background-color: #59595B;
    padding: 12px;
    justify-content: center;
    align-items: center;
}

.year4-content {
    margin-top: 46px;
    display: flex;
    flex-direction: column;
}

.year4-content img {
    width: 900px;
    height: auto;
}

.year5-content {
    margin-top: 29px;
    display: flex;
    flex-direction: column;
}
.year5-content p {
    width: 825px;
}

.year5-content h6 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.set1 {
    display: flex;
    flex-direction: row;
    gap: 19px;
}

.set1 img {
    width: 280px;
    height: auto;
}

.set2 {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.set2 img {
    height: 253px;
    width: auto;
}

.set3 {
    display: flex;
    flex-direction: row;
    gap: 7px;

}

.set3 img {
    height: 215px;
    width: auto;
}

.how-it-worked-container {
    width: 75vw;
    max-width: 865px;
    height: auto;
    display: flex;
    flex-direction: column;
   
}

.how-it-worked {
    display: flex;
    flex-direction: row;
    gap: 37px;

}
.worked-text {
    text-align: left;
    width: 410px;
    height: 352px;
}
.worked-text ol{
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-left: -22px;

}

.worked-image {
    display: flex;
    width: 441px;
    height: 289px;
}

.worked-image img {
    max-width: 36vw;
    height: auto;
}

.branding-container {
    width: 100%;
    margin-top: 50px;
    height: auto;
    
}
.branding-images {
    width: 68vw;
    max-width: 865px;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
   
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    background-color: #E2E2E2;
}

.branding-images img {
    width: 200px;
    
    height: auto;

}


.interface-images {
    width: 68vw;
    max-width: 865px;
    height: auto;
    gap: 20px;
    display: flex;
    flex-direction: row;
}
.interface-images img{
    width: 150px;
    height: auto;

}

.what-changed-p1 {
    display: flex;
    flex-direction: row;
    gap: 43px;
}
.what-changed-p1 p {
    width: 361px;
    height: 312px;
}
.what-changed-p1-img {
    width: auto;
    height: auto;
    display: flex;
}

.what-changed-p1-img img{
    display: flex;
    width: 450px;
    height: auto;
    
}
.what-changed-p2 {
    margin-top: 50px;
    width: 885px;
    display:flex;
    flex-direction:column
}
.what-changed-p2 p {
    width: 857px;
}
.what-changed-p2-img {
    width: 68vw;
    max-width: 865px;
    height: auto;
    gap: 15px;
    display: flex;
    flex-direction: row;
}
.what-changed-p2-img img {
    height: auto;
    width: 450px;
}

.results-container img {
    width: 68vw;
    max-width: 865px;
    height: auto;
}

.jogg-logos-2019 {
    display: flex;
    flex-direction: row;
    gap: 58px;
    justify-content: center;
    align-items: flex-end;
    height: 217px;
    width: 100%;
    flex-wrap: wrap;
}

.jogg-content label{
    font-family: Inter;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.logo-old {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}
.logo-old img {
    width: 343px;
    height: 173px
}

.logo-new {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.logo-new img {
    width: 337px;
    height: 89px;
}






.react-tabs {
    -webkit-tap-highlight-color: transparent;
    display: flex;
    flex-direction: row;
    gap: 62px;
    justify-content: center;
  }
  
  .react-tabs__tab-list {
    width: 124px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;


  }
  .stickylist {
    position: sticky;
    top: 80px;
    left: 0px;
    overflow: visible;
    height: 374px;
  }

  .stickylist-scrolled {
        position: absolute;
        bottom: 596px;

        margin-right: 1079px;
        overflow: visible;
        height: 374px;
  }

  .footer h3 {
    font-family: "Courier Prime";
    font-size: 24px;
    font-weight: 700;
    line-height: 26.95px;
    letter-spacing: 0.12em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

  }
  
  .react-tabs__tab {
    display: inline-block;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    font-family: 'Courier Prime';
    font-size: 14px;
    box-shadow: inset 0 -2px 0 -1px black;
    transition: box-shadow .3s ease-in-out;
  }

  .react-tabs__tab:hover {
    color: white;
    box-shadow: inset 0 -40px 0 -1px black;
  }
  
  .react-tabs__tab--selected {
    background-color: black;
    color: white;
  }
  
  .react-tabs__tab--disabled {
    color: gray;
    cursor: default;
  }
  
  .react-tabs__tab:focus {
    outline: none;
  }
  
  .react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }
  
  .react-tabs__tab-panel {
    display: none;
  }
  
  .react-tabs__tab-panel--selected {
    display: flex;
    margin: 0 10px;
    padding: 10px;
    width: 750px;

  }

.carousel-container {
    display: flex;
    background: #7C828B;

    width: 939px;
    height: 494px;
    gap: 10px;
    opacity: 0px;

}

.carousel-container button {
    background-color: #7C828B;
}
.carousel-container button:hover {
    background-color: #7C828B;
}

.custom-dot-list-style {
    margin-top: 10px;
}

.carousel-stuff {
    display:flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    height: 568px;
    max-width: 75vw;
}

.tab-navs {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 68vw;
}

.tab-navs button {
    
        border: 0;
        background-color: white;
        font-family: "Courier Prime";
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.08em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin-top: 10px;
        box-shadow: inset 0 -2px 0 -1px black;
        transition: box-shadow .3s ease-in-out;
        
}

.tab-navs button:hover {
    color: white;
    box-shadow: inset 0 -40px 0 -1px black;
}

.logo-changes {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 81px;
    width: 100%;
}

.logo-changes-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.logo-changes-text p {
    width: 380px;
}

.logo-changes-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 342px;
    height: 179px;
    gap: 50px;
    flex-wrap: wrap;
}

.logo-changes-image img {
    height: auto;

    max-width: 139px;
}

.animation-box {
    display: flex;
    align-items: center;
    width: 903px;
    height: 160px;
    background-color: #F5F5F5;
    margin-bottom: 50px;
}

.jogg-styles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 24px;
    margin-bottom: 50px;
}

.jogg-styles img {
    width: 390px;
    height: auto;
    padding: 20px;
    border: 2px solid #E0E0E0;
    border-radius: 10px;
}

.jogg-styles-old {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;

}

.jogg-styles-new {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;

}

.jogg-imagery {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 42px;
    margin-bottom: 50px;
}

.jogg-imagery-old {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;

}

.jogg-imagery-old img {
    width: 390px;
    height: 397px;
    padding: 20px;
    border: 2px solid #E0E0E0;
    border-radius: 10px;
}

.jogg-imagery-new {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;

}

.jogg-imagery-new img {
    width: 362px;
    height: 399px;
    padding: 20px;
    border: 2px solid #E0E0E0;
    border-radius: 10px;
}

.footer-2019 img {
    width: 903px;
    height: 353px;
}

.year6-content {
    display: flex;
    flex-direction: column;
    margin-top: 29px;

}

.year6-content p {
    width: 825px;
}

.year6-content ol {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-left: -20px;
    width: 628px;

}

.year6-content li {
    margin-bottom: 10px;
}

.tablet {
    display: flex;
    flex-direction: row;
    width: 901px;
    height: 609px;
    gap: 26px;
}

.quad-tablet {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 440px;
    height: 609px;
    gap: 20px;
}

.quad-tablet img {
    width: 200px;
    height: 294px;
}

.big-tablet {
    width: 435px;
    height: 609px;
}

.big-tablet img {
    width: 435px;
    height: 609px;
}

.year7-content {
    display: flex;
    flex-direction: column;
    margin-top: 48px;
}

.year7-content p {
    width: 825px;
}
.year7-content h6 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0px;
    margin-top: 0px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    letter-spacing: 0.02em;

}

.solution {
    display: flex;
    flex-direction: row;
    gap:33px;
    margin-bottom: 34px;
}

.solution p {
    width: 425px;
}

.solution img {
    width: 368px;
    height: 84px;
}

.distribution {
    margin-bottom: 34px;
}

.distribution img {
    width: 840px;
    height: 497px;

}

.divide-up {
    display: flex;
    flex-direction: row;
    gap: 23px;
    width: 55vw;
    height: 330px;
    margin-bottom: 50px;
    overflow: hidden;
}

.divide-up img {
    height: 200px;
    width: auto;
}

.require {
    display: flex;
    flex-direction: row;
    gap: 18px;
    width: 55vw;
    height: 241px;
    margin-bottom: 50px;
    overflow: hidden;
}

.require img {
    height: 241px;
    width: auto;
}

.year8-content {
    display: flex;
    flex-direction: column;
    margin-top: 29px;

}

.studio-logo {
    width: 903px;
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.studio-text {
    width: 466px;
}

.studio-img {
    position: relative;
    top: 29px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 18px;
}

.studio-img img {
    width: 320px;
    height: auto;
}

.studio2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
}
.studio2 img {
    width: 218px;
    height: auto;
}

.the-lineup {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.the-lineup img {
    width: 82px;
    height: 27px;
    margin-left: 5px;
    margin-right: 12px;
    position: relative;
    top: 23px;
}

.lineup-items {
    width: 902.27px;
    height: 95.44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 25px;
}

.clients-text {
    margin-bottom: 50px;
}

.conclusion {
    margin-top: 92px;
}

.clients {

    display: flex;
    flex-direction: column;
    margin-top: 39px;
    margin-bottom: 467px;
}

.clients-bg {
    background-color: #F5F5F5;
    min-height: 247px;
    height: 247px;
    width: 100vw;
    position: absolute;
    bottom: 400px;
    z-index: -1;
    left: 0px;

}

.clients-row-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 70px;
}
.clients-row-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 70px;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 362px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    margin-bottom: 180px;
}

.footer-border {
    background-color: #DAF2F5;
    width: 100vw;
    height: 38px;
    min-height: 38px;
    border-bottom: 2px solid #B6CFD3;
    z-index: -1;

}

.more-projects {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 13px;
    width: 100%;
    max-width: 1920px;
}

.more-projects p {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.more-jun {
    width: 636px;
    height: 86px;
    background: #E8E5DA;
    border-bottom: 2px solid #D1C7BE;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 45px;

}

.more-jun:hover {
    cursor:pointer
}

.juns-text-1 {
    position: relative;
    top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.juns-text-1 svg {
    width: 219px;
}

.more-statemints {
    width: 636px;
    height: 86px;
    background: #E7F2E5;
    border-bottom: 2px solid #C3D2C1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 45px;
    flex-wrap: nowrap;

}

.more-statemints:hover {
    cursor:pointer
}

.mint-text-1 {
    position: relative;
    top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mint-text-1 svg {
    width: 154px;
}







@media only screen and (max-width: 950px) {

    .dropdown-menu-jogg {
        display: flex;
        width: 320px;
        align-items: center;
        flex-direction: column;
        margin-top: 11px;
    }
    select {
        width: 320px;
        height: 44px;
        font-family: Courier Prime;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.08em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        padding: 10px 20px 10px 20px;
        gap: 0px;
        border-radius: 8px 8px 8px 8px;
        border: 1px 1px 1px 1px;


    }
    .jogg p {
        width: 313px !important;
    }

    .jogg {
        display: flex;
        align-items: center;
        width: 100vw;
    }
    
    

    .stickylist {
        display: none;
    }

    .tabular-layout-main {
        align-items: center;
        display: flex;
        flex-direction: column !important;
        width: 313px;
        height: auto !important;
        justify-content: flex-start !important;
        margin-top: 30px;
       
    }



    .jogg-section-1 {
        flex-direction: column;
        align-items: center;
        width: 100%;

    }

    .section-1-cluster {
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 10px;
    }

    .highlight {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        padding-left: 10px;
        width: 320px;
        height: 56px;
        background-color: #E8F6F8;
        margin-top: 11px;
        margin-bottom: 11px;
    }

    .section-1-text {
        width: 315px;
    }
    .section-1-image {
        position: static;
        right: 20px;
        width: 411px;
    }
    .section-1-image img {
        width: 411px;
        height: 302px;
    }
    .jogg-carousel {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 329px;
        gap: 10px;
        
    }
    .jogg-carousel img {
        position: relative;
        top: 0px;
        width:  297px;
        overflow: hidden;
    }
    .carousel-container {
        position: static;
        flex-direction: row;
        width: 400px;
        height: 274px;
        gap: 10px;
        overflow: hidden;
    
    }
    .carousel-container button {
        background-color: #7C828B;
    }
    .carousel-stuff {
        display:flex;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        height: 212px;
    }

    .custom-dot-list-style {
        display: none;
    
    }
    .react-tabs {
        width: 100%;
        display: flex;
        flex-direction: column!important;
        justify-content: flex-start;
        
    }


    .year1 {
        height: auto;
    }

    .year2 {
        height: auto;
    }

    .year1-content {
        flex-direction: column;
        align-items: center;
        width: 313px;
        margin-top: 0px;
    }
    .year2-content {
        flex-direction: column;
        align-items: center;
        margin-top: 250px;
        width: 313px;
        height: auto;
    }
    .timeine-container-main {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
        
    }
    .jogg h1 {
        flex-direction: column;
        align-self: flex-start;
        width: 313px;
        height: auto;
        font-family: "Courier Prime";
        font-size: 24px !important;
        font-weight: 700;
        line-height: 26.95px !important;
        letter-spacing: 0.12em !important;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

    }
    .timeline-container {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
        
    }
    .timeline {
        display: none !important;
    }
    .jogg-content {
        flex-direction: column;
        width: 313px;
        margin-top: -10px;
        align-items: center;
        max-width: 100vw;
    }
    .how-it-worked-container {
        flex-direction: column;
        align-items: center;
        width: 313px;
        position: static;
        height: 669px;
        margin-top: -30px;
    }

    .how-it-worked-container h2 {
        align-self: flex-start;
    }
    .how-it-worked {
        flex-direction: column;
        align-items: center;
        width: 313px;
        gap: 10px;
    }

    .worked-image {
        width: 313px;
        height: auto;
    }
    .worked-text {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }
    .how-it-worked ol {
        width: 313px;
    }
    .year6-content ol {
        width: 313px;
    }
    .worked-image img {
        width: 313px;
        height: 205px;
    }

    .branding-container {
        flex-direction: column;
        align-items: center;
        width: 313px;
        margin-top: 75px;
    }

    .branding-images {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 313px;
        height: 798px;
        gap: 4px;
        padding: 10px 0px;
    }

    .branding-images img {
        width: 144.5px;
        height: 123.6px;
    }

    .interface-container {
        flex-direction: column;
        align-items: center;
        width: 313px;
        margin-top: 250px;
        display: none;
    }

    .interface-images {
        flex-direction: column;
        align-items: center;
        width: 313px;

    }
    .mobile-years {
        display: flex;
        align-self: flex-start;
    }

    .what-changed-container {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }

    .what-changed-p1 {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }
    .what-changed-p1-img {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }
    .what-changed-p1 img {
        width: 311px;
        height: 212px;
    }

    .what-changed-p2 {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }

    .what-changed-p2-img {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }

    .what-changed-p2-img img {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }

    .results-container {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }

    .results-container img {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }

    .tab-navs {
        width: 320px;
        height: auto;
    }

    .tab-navs button {
        font-size: 12px;
    }

    .jogg h2 {
        font-family: "Courier Prime";
        font-size: 18px !important;
        font-weight: 700;
        line-height: 20.21px !important;
        letter-spacing: 0.12em !important;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        align-self: flex-start;
        margin-top: 30px;

    }

    .jogg h6 {
        align-self: flex-start;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

    }

    .year3-content {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }

    .year4-content {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }

    .year4-content img {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }

    .year3-box-mobile {
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        width: 313px;
        height: auto;
        background-color: #59595B;
    }

    .year3-box {
        display: none;
    }

    .year5-content {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }

    .year5-content img {
        width: 313px;
        height: auto;
    }
    .year6-content {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }
    .year7-content {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;

    }
    .year8-content {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }

    .jogg-logos-2019 {
        flex-direction: row;
        align-items: flex-end;
        width: 313px;
        height: auto;
        gap: 24px;
    }

    .logo-old img {
        width: 145px;
        height: 73px;
    }

    .logo-new img {
        width: 142.7px;
        height: 37.69px;
    }

    .jogg-headline p {
        font-size: 13px;
        text-align: center;
    }

    .logo-changes {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;

    }

    .logo-changes-image {
        width: 313px;
        flex-wrap: wrap;
        gap: 45px;
        height: auto;
    }

    .logo-changes-image img {
        max-width: 130px;
    }
    .animation-box {
        display: flex;
        align-items: center;
        width: 313px;
        height: 160px;
        background-color: #F5F5F5;
        margin-bottom: 0px;
        margin-top: 30px;
    }

    .jogg-styles {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }

    .jogg-styles-old img {
        width: 313px;
    }

    .jogg-styles-new img {
        width: 313px;
    }

    .jogg-imagery {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }

    .tablet {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }
    .quad-tablet {
        width: 313px;
        height: auto;
    }
    .quad-tablet img {
        width: 146px;
        height: 203px;
    }

    .big-tablet {
        width: 313px;
        height: auto;
    }

    .big-tablet img {
        width: 301px;
        height: 423px;

    }
    .solution {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }
    .solution img {
        width: 313px;
        height: auto;
    }
    .distribution {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }

    .distribution img {
        width: 313px;
        height: auto;
    }

    .divide-up {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }

    .divide-up img {
        width: 313px;
        height: auto;
    }

    .require {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }

    .require img {
        width: 313px;
        height: auto;
    }

    .conclusion {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    } 

    .footer {
        width: 313px;
        position: static;
        height: auto;
    }

    .lineup-items {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
        gap: 40px;
    }

    .the-lineup {
        width: 313px;
        height: auto;
        margin-top: 30px;
        margin-bottom: 30px;
        justify-content: center;
    }

    .the-lineup h2 {
        font-family: "Courier Prime";
        font-size: 18px;
        font-weight: 700;
        line-height: 20.21px;
        letter-spacing: 0.12em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

    }

    .the-lineup img {
        top: 15px;
        width: 62px;
        height: 20px;
    }

    .studio-text {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }
    .timeline-container-main {
        flex-direction: column !important;
        align-items: center !important;
        width: 313px !important;
        height: auto !important;
        margin-top: 0px !important;
    }

    .studio-logo {
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: auto;
    }

    .clients {
        display: none;
    }

    .clients-mobile {
        display: flex;
        flex-direction: row;
        width: 313px;
        height: auto;
        flex-wrap: wrap;
        gap: 30px;
        margin-top: 30px;
    }
    .react-tabs__tab-panel  {
        flex-direction: column !important;
        width: 313px !important;
        justify-content: flex-start !important;
        margin: 0px !important;
        padding: 0px !important;

        
    }

    .more-projects {
        flex-direction: column-reverse !important;
        align-items: center;
        width: 313px;
        height: auto;
    }

    .more-jun {
        display: flex;
        width: 100vw;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        margin-top: 75px;
        margin-bottom: 50px;
        height: 273px;
    }

    .juns-text-1 {
        display: flex;
        flex-direction: column;
        align-items: center !important;
        justify-content: center;
        width: 313px !important;
        position: static !important;
        overflow: hidden;
    }

    .juns-text-1 p {
        position: relative;
        left: 108px;
    }

    .juns-text-1 svg {
        width: 244px !important;
        height: 42px !important;
    }
    
    .juns-image {
        position: static;
        flex-direction: column;
        align-items: center;
        margin-top: -60px;
        width: 313px;
    }
    .juns-image img {
        width: 310px;
        height: 175px;
    }

    .more-statemints {
        display: flex;
        width: 100vw;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 5px;
        margin-top: 75px;
        margin-bottom: -30px;
        height: 273px;
    }
    .mint-image {
        position: relative;
        top: -50px;
    }
    .mint-image img {
        width: 310px;
        height: 175px;
    }

    .mint-text-1 {
        display: flex;
        flex-direction: column;
        align-items: center !important;
        width: 313px;
        position: relative !important;
        top: -20px !important;
    }

    .mint-text-1 svg {
        width: 220px !important;
    }

    .mint-text-1 p {
        position: relative;
        left: 20px;
    }

    .footer-border {
        margin-top: 60px;
    }

    .footer h2 {
        font-family: "Courier Prime";
        font-size: 24px !important;
        font-weight: 700;
        line-height: 26.95px !important;
        letter-spacing: 0.12em !important;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        position: relative !important;
        left: 3px;

    }
    #p1 {
        margin-top: -70px !important;
    }
    #p2 {
        margin-top: -130px !important;
    }
    #p3 {
        margin-top: -180px !important;
    }
    #p4 {
        margin-top: -230px !important;
    }
    #p5 {
        margin-top: -300px !important;
    }


    .tabular-layout-main-scrolled {
        display: flex;
        align-items: center;
        display: flex;
        flex-direction: column !important;
        width: 313px;
        height: auto !important;
        justify-content: flex-start !important;
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 81vw;
        gap: 62px;
        margin-left: 0px
    }

    .section-1-image img {
        max-width: 100vw;
    }

    .worked-image img {
        max-width: 100vw;
        height: auto;
    }




    .what-changed-p1-img img{
        display: flex;
        width: 313px;
        height: auto;
        
    }

    .clients-bg {
        display: none;
    }
    .stickylist-scrolled {
        display: none;
    }
    p {
        max-width: 100vw;
    }
}
@media only screen and (max-width: 1200px) and (min-width: 950px) {
    .timeline-container-main {
        margin-left: 60px;
    }

    .jogg-section-1 {
        margin-left: 80px;
    }
    .worked-image img {
        max-width: 25vw;
        height: auto;
    }
    .studio-img { 
        max-width: 400px;
    }

    .section-1-image {
        display: none;
    }

    .studio-img img {
        width: 200px;
        height: auto;
    }
    .studio2 img {
        width: 170px;
    }
    .lineup-items {
        flex-wrap: wrap;
        width: 600px;
        margin-top: 20px;
        justify-content: flex-start;
    }
    .clients {
        width: 600px;
        justify-content: flex-start;
    }
    .clients img {
        max-width: 15%;
    }

    .studio-logo {
        gap: 20px;
    }

    .studio-text {
        width: 350px;
    }
    .studio-text p {
        width: 350px;
    }
    .stickylist-scrolled {
        position: absolute;
        bottom: 496px;

        margin-right: 879px;
        overflow: visible;
        height: 374px;
  }

}