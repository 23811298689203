.home {
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top: 179px;
    min-height: 100vh;
    width: 100vw;
    overflow: hidden;
}
.splash {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 93px;
    width: 75vw;
    min-height: 470px;
}

.splash-animation  {
    max-height: 470px;
    overflow-y: hidden;

}
.name-underline {
    width: 3000px;
    height: 38px;
    display: inline-flex;
    position: relative;
    right: 2595px;
    bottom: 27px;
    margin-bottom: -27px;
    z-index: -1;
    background-color: #DAF2F5;
    border-bottom: 2px solid #B6CFD3;
}
.splash-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 389px;
}



.splash-email-animation {
    display: flex;
    width: 189;
    height: 78;
    margin-left: -30px;
    margin-top: -15px;
    
}
.splash-email-animation:hover {
    cursor:pointer;
}

.splash-animation-mobile {
    display: none;
}

.work {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100vw;
    overflow: hidden;
}
.work-header {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    margin-left: 50px;
    
    
}
.work-alignment {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}
.work-panel {
    display: inline-flex;
    position: relative;
    height: 38px;
    width: 3000px;
    
    background-color: #E8E5DA;
    z-index: -1;
    top: 17px;
    left: 2000px;
    margin-left: -2000px;
    border-bottom: 2px solid #D1C7BE;
}
.work-jogg {
    display: inline-flex;
    width: 890px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
}
.work-jogg-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: transform 0.2s ease-in, background-color 0.2s ease-in;
    z-index: 1;
}
.work-jogg-image {
    display: flex;
    width: 400px;
    height: 226px;
    transition: transform 0.2s ease-in, background-color 0.2s ease-in;
    z-index: 1;
}
.work-jogg-underline {
    display: inline;
    position: absolute;
    z-index: 0;
    top: 830px;
    left: 0px;
    background-color: #E8F6F8;
    width: 90vw;
    height: 140px;
    transition: transform 0.2s ease-in, border-bottom 0.2s ease-in;
}
.work-jogg-underline:hover {
    cursor:pointer;
}
.work-statemints {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
    width: 890px;
    

}
.work-statemints-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: transform 0.2s ease-in, background-color 0.2s ease-in;
    z-index: 1;
}
.work-statemints-image {
    display: flex;
    width: 400px;
    height: 226px;
    transition: transform 0.2s ease-in, background-color 0.2s ease-in;
    z-index: 1;
}
.work-statemints-underline {
    display: inline;
    position: absolute;
    z-index: -1;
    top: 1110px;
    right: 0px;
    background-color: #E7F2E5;
    width: 95vw;
    height: 140px;
    border-bottom: 2px solid #C3D2C1;
    transition: transform 0.2s ease-in, border-bottom 0.2s ease-in;
    z-index: 0;
}

.work-statemints-underline:hover {
    cursor:pointer;
}
.work-juncphotography {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 890px;
    gap: 50px;
    align-items: center;

}
.work-juncphotography-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    top: 10px;
    transition: transform 0.2s ease-in, background-color 0.2s ease-in;
    z-index: 1;
}

.work-juncphotography-text:hover,
.work-statemints-image:hover,
.work-statemints-text:hover,
.work-juncphotography-image:hover,
.work-jogg-text:hover,
.work-jogg-image:hover,
.work-jun-underline:hover {
    cursor: pointer;
}
.work-juncphotography-image {
    display: flex;
    width: 400px;
    height: 226px;
    transition: transform 0.2s ease-in, background-color 0.2s ease-in;
    z-index: 1;
}
.work-jun-underline {
    display: inline;
    position: absolute;
    z-index: 0;
    top: 1390px;
    left: 0px;
    background-color: #E8E5DA;
    width: 90vw;
    height: 140px;
    border-bottom: 2px solid #D1C7BE;
    transition: transform 0.2s ease-in, border-bottom 0.2s ease-in;
}

.work-footer {
    width: 100vw;
    height: 38px;
    border-bottom: 2px solid #B6CFD3;
    background-color: #DAF2F5;
}


.skills-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 60px;
    margin-left: 90px;
}

.skills-column-a {
    display: flex;
    flex-direction: column;
    gap: 60px;
}
.skills-column-b {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.skill-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 350px;
    height: 71px;
    gap: 6.5px;
    text-align: left;
}

.skill-panel-svg {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 71px;
    width: 66px;
    background-color: white;
}

.skill-panel-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
    width: 263px;
}

.skill-panel-text p{
    font-size: 12px;
}

.skills-footer {
    width: 100vw;
    height: 38px;
    border-bottom: 2px solid #C3D2C1;
    background-color: #E7F2E5;
    margin-top: 76px;
}

.about-me {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 80px;
    height: 280px;
    margin-bottom: 100px;
}

.about-image {
    display: flex;
    width: 364px;
    height: 280px;
    margin-top: 60px;
}

.about-text {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 506px;
    height: 280px;
    gap: 5px;
}

.about-text h2 {
    margin-bottom: 0px;
}

.getintouch {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.getintouch-within {
    width: 582px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.textfield {
    width: 582px;
    display: flex;
    flex-direction: column;
}

.textfield label {
    background-color: #FFFFFF;
    color: black;
    font-weight: 700;
    font-size: 14px;
    max-width: 240px;
    position: relative;
    top: 9px;
    left: 9px;
    text-align: center;
}

input {
    border: 1px solid black;
    border-radius: 6px;
    height: 40px;
    padding: 0px 18px;
    width: 577px;
}

input:hover {
    border-bottom: 2px solid black;
}

input:active {
    border: 2px solid black;
}
input:focus {
    border: 2px solid black;
    outline: none;
}

.message {
    display: flex;
    flex-direction: column;
    
    width: 582px;
}
.message label {
    background-color: #FFFFFF;
    color: black;
    font-weight: 700;
    font-size: 14px;
    max-width: 230px;
    position: relative;
    top: 9px;
    left: 9px;
    text-align: center;
}

label {
    font-family: "Courier Prime";
}

textarea {
    border: 1px solid black;
    border-radius: 6px;
    height: 161px;
    width: 577px;
    padding: 8px 18px;
}

textarea:hover {
    border-bottom: 2px solid black;
}
textarea:active {
    border-bottom: 2px solid black;
}
textarea:focus {
    border: 2px solid black;
    outline: none;
}
.submit {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
    margin-top: 20px;
}
.submit button {
    background-color: black;
    border: 1px solid black;
    border-radius: 6px;
    width: 96px;
    height: 32px;
    font-family: "Courier Prime";
    font-weight: 700;
    font-size: 14px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: background-color 0.1s ease-in

}

.submit button h4 {
    font-family: "Courier Prime";
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 0px;
    color: white;
    transition: color 0.1s ease-in
}

button:hover {
    cursor: pointer;
    background-color: white;
}

button:hover h4 {
    color: black;
}

button:disabled {
    background-color: gray;
    cursor: default;
}

button:disabled h4 {
    color: white;
}

.for-fun {
    display: flex;
    position: relative;
    left: 230px;
    bottom: 25px;
}

.for-fun button {
    border: 0;
    background-color: white;
    font-family: "Courier Prime";
    font-size: 14px;
    font-weight: 700;
    line-height: 9px;
    letter-spacing: 0.08em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    box-shadow: inset 0 -2px 0 -1px black;
    transition: box-shadow .3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 9px;
    
    
}

.for-fun button:hover {
    color: white;
    box-shadow: inset 0 -40px 0 -1px black;
}

@media only screen and (max-width: 950px) {

    .home {
        margin-top: 78px;
    }
    p {
        max-width: 315px;
    }

    h2 {
        font-family: Courier Prime;
        font-size: 24px;
        font-weight: 700;
        line-height: 26.95px;
        letter-spacing: 0.12em;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

    }

    .home {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .splash {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0px;
        max-width: 360px;
        margin-top: 0px;
        margin-bottom: 70px;
        z-index: 2;
        overflow: visible;
    }
    .splash-text {
        flex-direction: column;
        align-items: center;
    }
    .splash-text h1 {
        font-family: Courier Prime;
        font-size: 38px;
        font-weight: 700;
        line-height: 42.68px;
        letter-spacing: 0.12em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

    }
    .name-underline {
        width: 100vw;
        height: 30px;
        display: inline-flex;
        position: relative;
        right: 0px;
        left: -20px;
        bottom: 17px;
        margin-bottom: -5px;
        z-index: -1;
        background-color: #DAF2F5;
        border-bottom: 2px solid #B6CFD3;
    }

    .splash-email-animation {
        position: relative;
        right: 65px;
    }

    .splash-animation {
        display: flex;
        width: 313px;
    }
    .splash-animation  {
        display: none;
    }

    .splash-animation-mobile {
        display: flex;
    }

    .work {
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    .work-header {
        flex-direction: column;
        gap: 0px;
        align-items: center;
        width: 313px;
        margin-top: 30px;
    }

    .work-header h2 {
        align-self: center;
        position: relative;
        right: 15px;
    }

    .work-panel {
        position: absolute;
        top: 685px;
        height: 30px;
        width: 100%;
    }
    .work-jogg-image {
        position: static;
        flex-direction: column;
        align-items: center;
        margin-top: -40px;
    }

    .work-jogg-text {
        align-items: center;
        margin-top: -40px;
    }
    .work-jogg-image img {
        width: 310px;
        height: 175px;
    }

    .work-jogg-underline {
        display: flex;
        position: absolute;
        z-index: 0;
        top: 830px;
        left: 0px;
        background-color: #E8F6F8;
        width: 100vw;
        height: 273px;
        transition: transform 0.2s ease-in, border-bottom 0.2s ease-in;
    }
    .work-alignment {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }
    .work-jogg {
        display: flex;
        width: 360px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px
    }

        .work-jogg-image {
        position: static;
        flex-direction: column;
        align-items: center;
        margin-top: -40px;
        width: 100%;
    }

    .work-jogg-text {
        align-items: center;
        margin-top: -40px;
    }
    .work-jogg-image img {
        width: 310px;
        height: 175px;
    }

    .work-jogg-underline {
        display: flex;
        position: absolute;
        z-index: 0;
        top: 830px;
        left: 0px;
        background-color: #E8F6F8;
        width: 100vw;
        height: 273px;
        transition: transform 0.2s ease-in, border-bottom 0.2s ease-in;
    }
    .work-alignment {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }
    .work-jogg {
        display: flex;
        width: 360px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px
    }

    .work-statemints {
        display: flex;
        width: 360px;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 5px;

        margin-top: 60px;
        
    
    }
    .work-statemints-text {
        align-items: center;
        margin-top: -40px;
    }
    .work-statemints-image {
        position: static;
        flex-direction: column;
        align-items: center;
        margin-top: -40px;
        width: 100%;
    }

    .work-statemints-image img {
        width: 310px;
        height: 175px;
    }
    .work-statemints-underline {
        display: flex;
        position: absolute;
        z-index: 0;
        top: 1160px;
        left: 0px;
        background-color: #E7F2E5;
        width: 100vw;
        height: 273px;
        border-bottom: 2px solid #C3D2C1;
        transition: transform 0.2s ease-in, border-bottom 0.2s ease-in;
        z-index: 0;
    }
    
    .work-statemints-underline:hover {
        cursor:pointer;
    }
    .work-juncphotography {
        display: flex;
        width: 360px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;

        margin-top: 75px;
        margin-bottom: 50px;
    
    }
    .work-juncphotography-text {
        align-items: center;
        margin-top: -40px;
    }
    
    .work-juncphotography-image {
        position: static;
        flex-direction: column;
        align-items: center;
        margin-top: -40px;
        width: 100%;
    }
    .work-juncphotography-image img {
        width: 310px;
        height: 175px;
    }
    .work-jun-underline {
        display: flex;
        position: absolute;
        z-index: 0;
        top: 1500px;
        left: 0px;
        background-color: #E8E5DA;
        width: 100vw;
        height: 273px;
        border-bottom: 2px solid #C3D2C1;
        transition: transform 0.2s ease-in, border-bottom 0.2s ease-in;
        z-index: 0;
    }

    .skills-container {
        flex-direction: column;
        align-items: center;
        gap: 50px;
        width: 350px;
        margin-left: 0px;
        padding-left: 50px;
        
    }


    .skill-panel {
        width: 350px;
    }


    .skills-header {
        width: 100vw;
    }

    .skills-footer {
        width: 100vw;
    }

    .skills-container p {
        width: 220px;
    }
    .skills {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 350px;
    }

    .about-me {
        flex-direction: column;
        align-items: center;
        margin-top: 150px;
        margin-bottom: 300px;
        gap: 10px;
        width: 313px;
    }
    .about-text {
        flex-direction: column;
        align-items: center;
        width: 313px;
    }

    .about-image img {
        width: 332px;
        height: 255px;
        position: relative;
        right: 38px;
    }

    .for-fun {
        position: static;
    }
    
    .getintouch-within {
        width: 313px;
        align-items: center;
        text-align: left;
    }

    .getintouch-form {
        align-items: center;
    }
    .textfield {
        width: 275px;
    }
    input {
        width: 275px;
    }

    textarea {
        width: 275px;
    }
    .message {
        width: 315px;
    }

}