.nav {
    display: flex;

    width: 100%;
    max-height: 48px;

    position: fixed;
    top: 0px;
    z-index: 5;
    background: white;
}

.nav-web {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    gap: 36px;
    padding: 18px 46px;
    z-index: 5;
    background: white;
}

.nav a{
    
    text-decoration: none;
    color: black;
    
}

.nav-item a {
    box-shadow: inset 0 -2px 0 -1px black;
    transition: box-shadow .3s ease-in-out;
}

.nav-item a:hover {
    
    color: white;
    box-shadow: inset 0 -40px 0 -1px black;
}

.main-page-link a:hover{
    background-color: none;
    color: black;
    text-decoration: none;
}

.nav-mobile {
    display: none;
}

@media only screen and (max-width: 950px) {
    .nav {
        gap: 20px;
        padding-left: 0px;
        padding-right: 0px;
        position: fixed;
        justify-content: center;
        border-bottom: solid 19px black;
        
    }

    .nav-mobile {
        display: flex;
        flex-direction: row;
        height: 30px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 18px 30px;

        z-index: 5;
        background: white;
    }

    .nav-web {
        display: none;
    }

    #dropdown-basic-button {
        background: white;
        border: none;
    }

    .dropdown-menu {
        background: white;
        width: 100vw;
        position: absolute;
        left: 0px;
        top: 67px;
        
    }

    .dropdown {
        position: absolute;
        right: 20px;
    }

    .dropdown:hover {
        cursor: pointer;
    }

    .dropdown-1 {
        width: 100vw;
        height: 44px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: white;
        color: black;
        transition: 0s background-color ease-in;
        transition: 0s color ease-in;
    }

    .dropdown-1:hover {
        background-color: black;
        color: white;
    }


    
    


}